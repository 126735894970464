// Generated by ts-to-zod
import { z } from 'zod';

export const featuresSchema = z.object({
  showPodcastTranscriptions: z.boolean(),
  useAmpTranscription: z.boolean(),
  showHomepageHero: z.boolean(),
  showMarketingBanner: z.boolean(),
  showPodcastSearch: z.boolean(),
  podcastTritonTokenEnabled: z.boolean(),
  showUserEducationTour: z.boolean(),
  showRankers: z.boolean(),
  lyricsLengthMaxDeviation: z.number(),
});

export type Features = z.infer<typeof featuresSchema>;
