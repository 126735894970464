// Generated by ts-to-zod
import { z } from 'zod';

import { countryCodesEnum } from './country-codes.js';

export const environmentSchema = z.object({
  countryCode: countryCodesEnum,
  hostName: z.string(),
  hosts: z.object({
    account: z.string(),
    listen: z.string(),
  }),
  locale: z.string(),
  objectDbBucket: z
    .literal('web.listen.staging')
    .or(z.literal('web.listen.prod')),
  markPlayedThreshold: z.number(),
  supportedCountries: z.array(z.string()),
  terminalId: z.number(),
  territoryCode: z.string(),
  websiteUrl: z.string(),
});

export type Environment = z.infer<typeof environmentSchema>;
