// Generated by ts-to-zod
import { z } from 'zod';

export const iasSchema = z.object({
  enabled: z.boolean(),
  library: z.string(),
  anID: z.number(),
});

export type IAS = z.infer<typeof iasSchema>;
